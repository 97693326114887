<template>
  <div id="company-landing-page">
    <div class="container">
      <div>
        <stageline-back-button
          text="Back to Select Company"
        />

        <h2 class="title">
          Managing:
          <span class="company-name">
            {{ currentCompany?.name || '' }}
          </span>
        </h2>
      </div>
      <template v-if="loaded">
        <stageline-overview class="stageline-overview" />
        <company-overview class="company-overview" />
      </template>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CompanyLandingPage',
  components: {
    CompanyOverview:     () => import ('@/components/StagelineV2/companyOverview/CompanyOverview.vue'),
    StagelineOverview:   () => import('@/components/StagelineV2/companyOverview/StagelineOverview.vue'),
    StagelineBackButton: () => import('@/components/StagelineV2/shared/StagelineBackButton.vue'),
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompany',
    ]),
  },
  async mounted() {
    const companyId = this.$route.params.companyId
    if (companyId && companyId !== this.currentCompany.id) {
      await this.setCurrentCompany({ id: companyId })
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
  },
}
</script>

<style scoped lang="scss">
#company-landing-page {
  padding: 5em;

  .title {
    display:block;
    text-align: left;
    font-weight: 800;
    width: 100%;
  }

  .company-name {
    color: #000864;
  }

  .stageline-overview, .company-overview {
    max-width: 1400px;
  }
}

@media (max-width: 431px) {
  #company-landing-page {
    padding: 3em;
  }
}
</style>
